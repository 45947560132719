<template>
  <div>
    <v-container>
      <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <v-card-title class="d-flex justify-space-around">
          <h1 class="font-weight-medium text-center">Reporte de reclamaciones SSPD</h1>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="4" md="4">
              <span
                class="
                  text-subtitle-2
                  col-lg-12 col-md-12 col-sm-12 col-xs-12
                  pt-0
                  pb-0
                "
                :style="{ color: 'initial !important' }"
                >Servicio</span
              >

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
                <v-autocomplete
                  outlined
                  dense
                  hide-details
                  clearable
                  type="text"
                  :items="data_servicios"
                  v-model="form.srv_id"
                ></v-autocomplete>
              </div>
            </v-col>

            <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="4" md="4">
              <span
                class="
                  text-subtitle-2
                  col-lg-12 col-md-12 col-sm-12 col-xs-12
                  pt-0
                  pb-0
                "
                :style="{ color: 'initial !important' }"
                >Rango de fecha (radicados)</span
              >

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
                <v-menu
                  v-model="date_radicado.estado"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_radicado.value"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      hide-details
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker                    
                    scrollable
                    v-model="date_radicado.value"
                    class="pa-0"
                    type="month"
                    locale="es"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="4" md="4">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
                &nbsp;
              </div>

              <div
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pa-0"
              >
                <v-btn
                  class="light-blue darken-1 white--text"
                  @click="get_report"
                >
                  consultar
                  <v-icon dark> &nbsp; mdi-magnify </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="data_report.length > 0">
      <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
        <v-card-text class="pa-0">
          <v-data-table
            class="my-5"
            :headers="headers"
            :items="data_report"
            item-key="_id"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn color="red darken-2" dark @click="create_pdf">
                  Pdf &nbsp;
                  <v-icon dark> mdi-file-pdf </v-icon>
                </v-btn>

                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="green darken-2" dark @click="create_csv">
                  Excel &nbsp;
                  <v-icon dark> mdi-file-excel </v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from 'moment';

export default {
  data() {
    return {
      data_servicios: [{ value: 1, text: "Todos los servicios" }],
      form: {
        srv_id: "**",
      },
      date_radicado: {
        estado: false,
        value: null,
      },
      data_report: [],
      headers: [
        { text: "Servicio", value: "srv_nombre" },
        { text: "Numero radicado", value: "frcl_id_rad_rec" },
        { text: "Causal", value: "csl_det" },
      ],

      data_impresion: {
        header: [
          {
            name: "SERVICIO",
            pointer: "srv_cdgo",
            filterButton: true,
          },
          { 
            name: "CÓDIGO DANE", 
            pointer: "frcl_cdgo_dane", 
            filterButton: true 
          },
          {
            name: "RADICADO RECIBIDO",
            pointer: "frcl_id_rad_rec",
            filterButton: true,
          },
          {
            name: "FECHA RADICACIÓN",
            pointer: "frcl_fc_rad",
            filterButton: true,
            //format: "date",
          },
          {
            name: "TIPO DE TRÁMITE",
            pointer: "tpt_cdgo",
            filterButton: true,
          },
          {
            name: "CAUSAL",
            pointer: "csl_cdgo",
            filterButton: true,
          },
          {
            name: "DETALLE DE LA CAUSAL",
            pointer: "csl_det",
            filterButton: true,
          },

          { name: "NÚMERO DE CUENTA", pointer: "frcl_id_cta", filterButton: true },
          { name: "NÚMERO IDENTIFICADOR DE LA FACTURA", pointer: "frcl_id_fact", filterButton: true },

          {
            name: "TIPO DE RESPUESTA",
            pointer: "tpr_cdgo",
            filterButton: true,
          },
          {
            name: "FECHA RESPUESTA",
            pointer: "frcl_fc_rpt",
            filterButton: true,
            //format: "date",
          },
          {
            name: "RADICADO RESPUESTA",
            pointer: "frcl_id_rad_rpt",
            filterButton: true,
          },

          {
            name: "FECHA DE NOTIFICACIÓN O EJECUCIÓN",
            pointer: "frcl_fc_ntf",
            filterButton: true,
            //format: "date",
          },
          {
            name: "TIPO DE NOTIFICACIÓN",
            pointer: "tpn_cdgo",
            filterButton: true,
          },

          {
            name: "FECHA TRASLADO A SSPD",
            pointer: "frcl_fc_tld_sspd",
            filterButton: true,
            //format: "date",
          },
          { name: "OBSERVACIONES", pointer: "frcl_obsv", filterButton: true },
        ],
        content: [],
      },
    };
  },
  created() {
    this.getServicios();
  },
  methods: {
    getServicios() {
      let uri = "/servicios/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "srv_cdgo", "srv_nombre"];
          this.data_servicios = await this.gb.orderData(params);

          this.data_servicios.push({
            value: "**",
            text: "Todos los servicios",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    get_report() {
      var di = this.date_radicado.value + '-' + '01';

      let year = parseInt(di.substr(0, 4));
      let month = parseInt(di.substr(5, 2));
      let lf = new Date(year, month, 0);
      let ld = lf.getDate();

      var df = this.date_radicado.value + '-' + ld;
      
      let date = [di, df] || [];
      let srv_id = this.form.srv_id;

      let data = {
        date_ini: date[0],
        date_fin: date[1],
        cmp_id: String(this.$store.state.company),
        sspd: true
      };

      if (srv_id != "**") data.srv_id = srv_id;

      var date_fin = new Date(df);

      this.axios
        .post("/formatoreclamos/report/reclamos", data)
        .then((response) => {
          var list = response.data;
          list.forEach(function(element, index, array) {
            debugger;

            if (!element.frcl_cdgo_dane){
              element.frcl_cdgo_dane = '';
            }

            if (element.frcl_fc_rad){
              element.frcl_fc_rad = moment(element.frcl_fc_rad).format('YYYY-MM-DD');
            } else {
              element.frcl_fc_rad = '';
            }

            let frcl_fc_rpt = new Date(element.frcl_fc_rpt);
            
            if (!element.frcl_fc_rpt || frcl_fc_rpt >= date_fin){
              element.tpr_cdgo = '';
              element.tpr_nombre = '';
              element.frcl_fc_rpt = '';
              element.frcl_id_rad_rpt = '';
            } else {
              element.frcl_fc_rpt = moment(element.frcl_fc_rpt).format('YYYY-MM-DD');
            }

            let frcl_fc_ntf = new Date(element.frcl_fc_ntf);

            if (!element.frcl_fc_ntf || frcl_fc_ntf >= date_fin){
              element.frcl_fc_ntf = '';
              element.tpn_cdgo = '';
              element.tpn_nombre = '';
            } else {
              element.frcl_fc_ntf = moment.utc(element.frcl_fc_ntf).format('YYYY-MM-DD');
            }
            
            let frcl_fc_tld_sspd = new Date(element.frcl_fc_tld_sspd);

            if (!element.frcl_fc_tld_sspd || frcl_fc_tld_sspd >= date_fin){
              element.frcl_fc_tld_sspd = '';
            } else {
              element.frcl_fc_tld_sspd = moment.utc(element.frcl_fc_tld_sspd).format('YYYY-MM-DD');
            }            
          });          

          this.data_report = list;
        })
        .catch((err) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },
    async create_csv() {
      this.data_impresion.content = _.cloneDeep(this.data_report);

      let datos = {
        tipo: "excel",
        datos: this.data_impresion,
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },

    async create_pdf() {
      this.data_impresion.content = _.cloneDeep(this.data_report);
      let datos = {
        tipo: "pdf",
        datos: this.data_impresion,
        formato: "FormatoReclamos",
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>

<style>
.v-data-table .v-toolbar.v-toolbar--flat {
  display: flex;
  justify-content: flex-end;
}
</style>